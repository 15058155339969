const URL_STRING_LIMIT = 120;

/**
 * Converts text to URL-friendly slug
 * @param {string} text - Input text (max 120 chars)
 * @returns {string} URL-safe slug
 */
export function toUrlSafeString(text) {
  return text
    .trim()
    .slice(0, URL_STRING_LIMIT)
    .toLowerCase()
    .replace(/[^a-z0-9\s-]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-")
    .replace(/^-+|-+$/g, "");
}
