import React from "react";

import { useAuth } from "@wc/domain/authn";

import { loadOrganization } from "../core/org-access";
import { updateOrganization } from "../core/org-update";

/**
 *
 * @param {object} org The organization or null
 * @param {Function} setOrg To set the organization
 * @param {boolean} loading Whether the org is loading or not
 * @param {Function} _setLoading Set the org to not load anymore
 * @param {object} authData The auth context data
 * @returns {object} The org context data
 */
function createOrgContextData(org, setOrg, loading, _setLoading, authData) {
  return {
    org,
    isLoading: loading,
    updateOrgDisplayName: async (newName) => {
      if (newName.length === 0) {
        throw new Error("Unable to set an empty name");
      }
      const oldName = org.displayName;

      const newOrg = { ...org };
      newOrg.displayName = newName;
      setOrg(newOrg);
      try {
        await updateOrganization(newOrg, authData.getCurrentUser());
      } catch (error) {
        console.error(error);
        const backToOldName = { ...org };
        backToOldName.displayName = oldName;
        setOrg(backToOldName);
      }
    },
  };
}

export const OrgContext = React.createContext(null);

/**
 * @param {object} props The provider props
 * @param {string} props.orgName The name of the organization
 * @param {string} props.userId The id of the user looking for this
 * @param {React.ReactElement} props.children The children to render
 * @returns {React.ReactElement} The organization provider
 */
export default function OrgProvider({ orgName, userId, children }) {
  const authData = useAuth();
  const [org, setOrg] = React.useState(null);

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchOrg = async () => {
      try {
        const loadedOrg = await loadOrganization(orgName, userId);
        setOrg(loadedOrg);
        setLoading(false);
      } catch (error) {
        console.error(`Could not load org ${orgName}`, error);
        setOrg(null);
      }
    };
    if (!org) {
      fetchOrg();
    }
  }, [orgName, userId, org]);

  const contextData = createOrgContextData(
    org,
    setOrg,
    loading,
    setLoading,
    authData,
  );

  return (
    <OrgContext.Provider value={contextData}>{children}</OrgContext.Provider>
  );
}
