import { Spinner } from "@nextui-org/react";
import React from "react";

import { useOrg, OrgInfo } from "@wc/domain/organizations";
import usePageTitle from "@wc/ui/hooks/page-title";

/**
 * @returns {React.ReactElement} view and edit org information
 */
export default function OrgInfoPage() {
  const orgData = useOrg();

  usePageTitle(orgData.org?.displayName || "");

  if (!orgData.org) {
    return (
      <div className="mt-8 flex justify-center">
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <div className="m-5">
      <OrgInfo readOnly={false} />
    </div>
  );
}
