import FirebaseAdapter from "@wc/adapters/firebase";
import { signup } from "@wc/domain/org-membership";
import { getCurrentUser } from "@wc/domain/users";

/**
 * @returns {boolean} A successful logout or not
 */
export async function logout() {
  try {
    await FirebaseAdapter().authn.signout();
    return true;
  } catch (error) {
    console.error("unable to log out", error);
    return false;
  }
}

/**
 * Use google to log in. Create a user if they don't exist
 * @returns {boolean} A successful login
 */
export async function loginGoogle() {
  try {
    await FirebaseAdapter().authn.signInWithGoogle();
  } catch (error) {
    console.error("Unable to log in", error);
    return false;
  }

  let currentUser = null;
  try {
    currentUser = await getCurrentUser();
  } catch (error) {
    console.error("Cannot get user", error);
  }

  if (!currentUser) {
    const authUser = FirebaseAdapter().authn.getCurrentUser();

    await signup({
      displayName: authUser.displayName,
      email: authUser.email,
      id: authUser.uid,
    });
  }

  return true;
}
