import FirebaseAdapter from "@wc/adapters/firebase";
import { numericToRole } from "@wc/domain/authz";

/**
 * @typedef { import("../models").AuthnUser } AuthnUser
 */

/**
 * Gets the current user if available.
 * @returns {AuthnUser} The user
 */
export async function getCurrentUser() {
  const firebaseUser = FirebaseAdapter().authn.getCurrentUser();

  if (!firebaseUser) {
    throw new Error("User not logged in");
  }

  const dbUser = await FirebaseAdapter().users.getUserById(firebaseUser.uid);
  const organization = await FirebaseAdapter().organizations.getOrganization(
    dbUser.metadata.activeOrganizationId,
  );
  const orgRole = await FirebaseAdapter().roles.getOrgRole(
    dbUser.id,
    organization.id,
  );

  return {
    id: dbUser.id,
    displayName: dbUser.profile.displayName,
    email: dbUser.profile.email,
    photoURL: dbUser.profile.photoURL,
    activeOrganization: {
      id: organization.id,
      displayName: organization.displayName,
      name: organization.name,
      role: numericToRole(orgRole.role),
    },
    lastLogin: dbUser.lastLogin,
    theme: dbUser.settings.theme,
  };
}
