import {
  addDoc,
  collection,
  doc,
  setDoc,
  query,
  where,
  getDocs,
  getDoc,
  limit,
  startAfter,
  serverTimestamp,
} from "firebase/firestore";

import { Collections } from "./constants";

/**
 * @typedef { import('./types').NoSQLOrganization } NoSQLOrganization
 */

/**
 * @param {import('firebase/firestore').Firestore} db The firestore database
 * @returns {object} The access functions for the organization
 */
export default function organizationsCollectionFns(db) {
  /**
   * Lists organizations with pagination
   * @param {object} options DB query options
   * @param {number} [options.limit] Max number of results to return
   * @param {import('firebase/firestore').DocumentSnapshot} [options.startAfterDoc] Document snapshot to start after for pagination
   * @returns {Promise<NoSQLOrganization[]>} A list of organizations
   */
  async function listOrganizations({ limit = 10, startAfterDoc = null } = {}) {
    try {
      let q = query(
        collection(db, Collections.ORGANIZATIONS),
        where("deletedAt", "==", null),
        limit(limit),
      );

      if (startAfterDoc) {
        q = query(q, startAfter(startAfterDoc));
      }

      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error("Failed to list organizations:", error);
      throw error;
    }
  }

  /**
   * @param {string} orgId The id of the organization to find
   * @returns {Promise<NoSQLOrganization|null>} An organization or nothing
   */
  async function getOrganization(orgId) {
    try {
      const docRef = doc(db, Collections.ORGANIZATIONS, orgId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists() || docSnap.data().deletedAt) return null;
      return { id: docSnap.id, ...docSnap.data() };
    } catch (error) {
      console.error("Failed to get organization:", error);
      throw error;
    }
  }

  /**
   * @param {string} name the name of the organization to find
   * @returns {Promise<NoSQLOrganization|null>} The organization or nothing
   */
  async function getOrganizationByName(name) {
    try {
      const q = query(
        collection(db, Collections.ORGANIZATIONS),
        where("name", "==", name),
        where("deletedAt", "==", null),
        limit(1),
      );

      const snapshot = await getDocs(q);

      if (snapshot.empty) return null;
      const doc = snapshot.docs[0];
      return { id: doc.id, ...doc.data() };
    } catch (error) {
      console.error("Failed to get organization by name:", error);
      throw error;
    }
  }

  /**
   * @param {NoSQLOrganization} organization The organization to save
   * @returns {Promise<string>} Returns the organization ID
   */
  async function saveOrganization(organization) {
    const { id: orgId, ...orgData } = organization;

    const cleanedOrgData = {
      ...orgData,
      createdAt: serverTimestamp(),
      deletedAt: null,
    };

    try {
      if (orgId) {
        const docRef = doc(db, Collections.ORGANIZATIONS, orgId);
        await setDoc(docRef, cleanedOrgData);
        return orgId;
      } else {
        const collectionRef = collection(db, Collections.ORGANIZATIONS);
        const docRef = await addDoc(collectionRef, cleanedOrgData);
        return docRef.id;
      }
    } catch (error) {
      console.error("Failed to save organization:", error);
      throw error;
    }
  }

  /**
   * @param {string} orgId The id of the organization to delete
   * @param {string} deletedBy the id of the user who deleted
   * @returns {Promise<void>} nothing
   */
  async function deleteOrganization(orgId, deletedBy) {
    try {
      const docRef = doc(db, Collections.ORGANIZATIONS, orgId);
      await updateDoc(docRef, {
        deletedAt: serverTimestamp(),
        deletedBy,
      });
    } catch (error) {
      console.error("Failed to delete organization:", error);
      throw error;
    }
  }

  return {
    listOrganizations,
    getOrganization,
    getOrganizationByName,
    saveOrganization,
    deleteOrganization,
  };
}
