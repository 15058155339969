import FirebaseAdapter from "@wc/adapters/firebase";
import { toUrlSafeString } from "@wc/domain/strings";

/**
 * @param {object} orgInfo basic needed organization info
 * @param {string} orgInfo.name The name of the organization
 * @param {string|null} [orgInfo.id] the id of the organization
 * @returns {string} The created database id
 */
export default async function createOrganization({ name, id = null }) {
  const slug = toUrlSafeString(name);

  const dbId = await FirebaseAdapter().organizations.saveOrganization({
    id: id,
    displayName: name,
    name: slug,
    createdAt: new Date(),
    deletedAt: null,
    settings: {
      plan: "free",
    },
  });

  return dbId;
}
