import FirebaseAdapter from "@wc/adapters/firebase";
import { PermissionsEnum, userHasPermissionInOrg } from "@wc/domain/authz";

/**
 *
 * @param {object} org The organization with updated values
 * @param {object} user The user taking the action
 * @returns {void}
 */
export async function updateOrganization(org, user) {
  let hasPerm = false;
  try {
    hasPerm = await userHasPermissionInOrg(
      user,
      org.id,
      PermissionsEnum.MANAGE_ORGANIZATION,
    );
  } catch {
    throw new Error("Unable to update organization");
  }

  if (!hasPerm) {
    throw new Error("Unable to update organization");
  }

  await FirebaseAdapter().organizations.saveOrganization(org);
  console.log(org);
}
