import { Input, Kbd } from "@nextui-org/react";
import React from "react";
import { isMacOs, isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";

/**
 *
 * @param {object} props Component props
 * @param {string} props.value The string to display
 * @param {boolean} [props.isDisabled] Whether to disable the input
 * @param {Function} props.onSave Function to call when the value is saved
 * @param {Function} [props.onChange] Function to call when the value changes
 * @returns {React.ReactElement} The input
 */
export default function EditableInput({
  value,
  isDisabled = false,
  onSave,
  onChange,
}) {
  const { t } = useTranslation();
  const [isDirty, setIsDirty] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(value);

  const enterKeyName = isMacOs || isIOS ? t("keyReturn") : t("keyEnter");

  /**
   *
   * @param {object} e The event
   */
  function handleChange(e) {
    setInputValue(e.target.value);
    setIsDirty(e.target.value !== value);
    onChange?.(e);
  }

  /**
   *
   */
  function handleCancel() {
    setInputValue(value);
    setIsDirty(false);
  }

  /**
   *
   */
  async function handleSave() {
    console.log(inputValue);
    await onSave(inputValue);
    setIsDirty(false);
  }

  return (
    <div className="relative grow">
      <Input
        type="text"
        size="lg"
        variant="bordered"
        radius="sm"
        isDisabled={isDisabled}
        value={inputValue}
        onChange={handleChange}
        onKeyDown={async (e) => {
          if (e.target.value === value) {
            return;
          }
          if (e.key === "Enter") await handleSave();
          if (e.key === "Escape") handleCancel();
        }}
      />
      {isDirty && (
        <div className="absolute -bottom-2 -right-2 flex gap-2">
          <Kbd keys={["enter"]}>
            {t("hitEnter", { keyName: enterKeyName })} to save
          </Kbd>
          <Kbd keys={["esc"]}>esc to cancel</Kbd>
        </div>
      )}
    </div>
  );
}
