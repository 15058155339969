import React from "react";

import { AuthContext } from "../contexts/auth-context";

/**
 * @typedef { import("../contexts/auth-context").AuthContextData } AuthContextData
 */

/**
 * Custom hook to access authentication context
 * @returns {AuthContextData} Authentication context containing user data and auth methods
 * @throws {Error} If used outside of AuthProvider
 */
export default function useAuth() {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
