import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { AuthnLayout } from "@wc/domain/authn";
import { CenteredPageLayout } from "@wc/domain/layouts";
import { OrgSettingsLayout } from "@wc/domain/organizations";

import Home from "./home";
import Login from "./home/login";
import Orgs from "./home/orgs";
import OrgName from "./home/orgs/[orgName]";
import OrgInfoPage from "./home/orgs/[orgName]/info";
import OrgMembers from "./home/orgs/[orgName]/members";
import OrgOverview from "./home/orgs/[orgName]/overview";

/**
 * @returns {React.ReactElement} The Router Provider for the app
 */
export default function WCRouter() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <CenteredPageLayout>
          <Home />
        </CenteredPageLayout>
      ),
    },
    {
      path: "/login",
      element: (
        <CenteredPageLayout>
          <Login />
        </CenteredPageLayout>
      ),
    },
    {
      element: <AuthnLayout />,
      children: [
        {
          element: (
            <CenteredPageLayout>
              <OrgSettingsLayout />
            </CenteredPageLayout>
          ),
          children: [
            {
              path: "/orgs/:orgName",
              element: <OrgName />,
            },
            {
              path: "/orgs/:orgName/overview",
              element: <OrgOverview />,
            },
            {
              path: "/orgs/:orgName/info",
              element: <OrgInfoPage />,
            },
            {
              path: "/orgs/:orgName/members",
              element: <OrgMembers />,
            },
          ],
        },
        {
          path: "/orgs",
          element: (
            <CenteredPageLayout>
              <Orgs />
            </CenteredPageLayout>
          ),
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}
