import React from "react";

import FirebaseAdapter, {
  initializeFirebaseAdapter,
} from "@wc/adapters/firebase";
import { BuildConfig } from "@wc/domain/config";
import { getCurrentUser } from "@wc/domain/users";

import { logout, loginGoogle } from "../core/basic-actions";

/**
 * @typedef { import("@wc/domain/users/models").AuthnUser } AuthnUser
 */

initializeFirebaseAdapter(
  BuildConfig.FIREBASE_API_KEY,
  BuildConfig.FIREBASE_AUTH_DOMAIN,
  BuildConfig.FIREBASE_PROJECT_ID,
  BuildConfig.FIREBASE_APP_ID,
);

/**
 * We need access to the user, whether they are logged in,
 * And then actions that can log them in or out.
 * @typedef {object} AuthContextData
 * @property {() => AuthnUser} getCurrentUser Access to a user if they exist, errors otherwise
 * @property {() => boolean} isLoggedIn Whether any users are logged in at the moment
 * @property {() => void} logout Log the current user out
 * @property {() => boolean} loginOAuth Log in through an oauth method
 * @property {() => boolean} isLoading Whether we're loading the user still
 */

// --- Context & Provider ------------------------

/**
 * @param {AuthnUser | null} user The user or null
 * @param { Function } setUser A function to set the user, from a setState
 * @param { boolean } loading Whether we should be loading or not
 * @param { Function } _setLoading A function to load the user
 * @returns {AuthContextData} Information and actions to work with auth
 */
function createAuthContextData(user, setUser, loading, _setLoading) {
  return {
    isLoggedIn: () => {
      return user !== null;
    },
    getCurrentUser: () => {
      if (user) {
        return user;
      } else {
        throw new Error("No user available");
      }
    },
    logout: async () => {
      const success = await logout();
      if (success) {
        setUser(null);
      }
    },
    loginOAuth: async () => {
      const success = await loginGoogle();
      if (success) {
        setUser(getCurrentUser());
      }
      return success;
    },
    isLoading: () => {
      return loading;
    },
  };
}

export const AuthContext = React.createContext(null);

/**
 *
 * @param {object} props Props for the context
 * @param {React.Element} props.children Children that need the context's scope
 * @returns {React.Element} The AuthContext
 */
export default function AuthProvider({ children }) {
  const [user, setUser] = React.useState(null);
  /** @type { boolean }  */
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const unsubscribe = FirebaseAdapter().authn.onAuthStateChanged(
      async (firebaseUser) => {
        if (firebaseUser) {
          const currentUser = await getCurrentUser();
          if (currentUser) {
            setUser(currentUser);
          } else {
            setUser(null);
          }
        } else {
          setUser(null);
        }
        setLoading(false);
      },
    );
    return () => unsubscribe();
  }, []);

  const contextData = createAuthContextData(user, setUser, loading, setLoading);

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
}
