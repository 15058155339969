/**
 * @typedef { import("./db-models").NoSQLTeamMember } NoSQLTeamMember
 * @typedef { import("./db-models").NoSQLOrganizationMember } NoSQLOrganizationMember
 */

import {
  collection,
  doc,
  getDoc,
  setDoc,
  query,
  limit as fbLimit,
  where,
  getDocs,
  startAfter,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";

import { Collections } from "./constants";

/**
 * A top level function to keep track of common inputs to all functions.
 * @param {import('firebase/firestore').Firestore} db The firestore db
 * @returns {object} The functions to access the various membership collections
 */
export default function membershipCollectionsFns(db) {
  /**
   * @param {string} userId the id of the user to add
   * @param {string} orgId the id of the organization to find
   * @returns {Promise<void>} nothing
   */
  async function addUserToOrg(userId, orgId) {
    try {
      const docRef = doc(
        db,
        Collections.ORGANIZATION_MEMBERS,
        `${orgId}_${userId}`,
      );

      await setDoc(docRef, {
        userId,
        organizationId: orgId,
        joinedAt: serverTimestamp(),
        deletedAt: null,
      });
    } catch (error) {
      console.error("Failed to add user to organization:", error);
      throw error;
    }
  }

  /**
   * @param {string} userId The id of the user to add
   * @param {string} teamId The id of the team to add to
   * @returns {Promise<void>} nothing
   */
  async function addUserToTeam(userId, teamId) {
    try {
      const docRef = doc(db, Collections.TEAM_MEMBERS, `${teamId}_${userId}`);

      await setDoc(docRef, {
        userId,
        teamId,
        joinedAt: serverTimestamp(),
        deletedAt: null,
      });
    } catch (error) {
      console.error("Failed to add user to team:", error);
      throw error;
    }
  }

  /**
   * @param {string} userId the id of the user to remove
   * @param {string} orgId the id of the org
   * @returns {Promise<void>} nothing
   */
  async function removeUserFromOrg(userId, orgId) {
    try {
      const docRef = doc(
        db,
        Collections.ORGANIZATION_MEMBERS,
        `${orgId}_${userId}`,
      );

      await updateDoc(docRef, {
        deletedAt: serverTimestamp(),
      });
    } catch (error) {
      console.error("Failed to remove user from organization:", error);
      throw error;
    }
  }

  /**
   * @param {string} userId the id of the user to remove
   * @param {string} teamId the id of the team
   * @returns {Promise<void>} nothing
   */
  async function removeUserFromTeam(userId, teamId) {
    try {
      const docRef = doc(db, Collections.TEAM_MEMBERS, `${teamId}_${userId}`);

      await updateDoc(docRef, {
        deletedAt: serverTimestamp(),
      });
    } catch (error) {
      console.error("Failed to remove user from team:", error);
      throw error;
    }
  }

  /**
   * Lists organizations a user is a member of
   * @param {string} userId The id of the user to see all of their orgs
   * @param {object} options DB query options
   * @param {number} [options.limit] Max number of results to return
   * @param {import('firebase/firestore').DocumentSnapshot} [options.startAfterDoc] Document snapshot to start after for pagination
   * @returns {Promise<NoSQLOrganizationMember[]>} A list of org memberships, for a single user
   */
  async function listUserOrgs(
    userId,
    { limit = 10, startAfterDoc = null } = {},
  ) {
    try {
      let q = query(
        collection(db, Collections.ORGANIZATION_MEMBERS),
        where("userId", "==", userId),
        where("deletedAt", "==", null),
        ...(limit > 0 ? [fbLimit(limit)] : []),
      );

      if (startAfterDoc) {
        q = query(q, startAfter(startAfterDoc));
      }

      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Failed to list user organizations:", error);
      throw error;
    }
  }

  /**
   * Lists all members of an organization
   * @param {string} orgId The id of the organization
   * @param {object} options DB query options
   * @param {number} [options.limit] Max number of results to return
   * @param {import('firebase/firestore').DocumentSnapshot} [options.startAfterDoc] Document snapshot to start after for pagination
   * @returns {Promise<NoSQLOrganizationMember[]>} A list of org memberships, all users
   */
  async function listOrgMembers(
    orgId,
    { limit = 10, startAfterDoc = null } = {},
  ) {
    try {
      let q = query(
        collection(db, Collections.ORGANIZATION_MEMBERS),
        where("organizationId", "==", orgId),
        where("deletedAt", "==", null),
        ...(limit > 0 ? [fbLimit(limit)] : []),
      );

      if (startAfterDoc) {
        q = query(q, startAfter(startAfterDoc));
      }

      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Failed to list organization members:", error);
      throw error;
    }
  }

  /**
   * Lists all members of a team
   * @param {string} teamId The id of a team
   * @param {object} options DB query options
   * @param {number} [options.limit] Max number of results to return
   * @param {import('firebase/firestore').DocumentSnapshot} [options.startAfterDoc] Document snapshot to start after for pagination
   * @returns {Promise<NoSQLTeamMember[]>} A list of team memberships, all users
   */
  async function listTeamMembers(
    teamId,
    { limit = 10, startAfterDoc = null } = {},
  ) {
    try {
      let q = query(
        collection(db, Collections.TEAM_MEMBERS),
        where("teamId", "==", teamId),
        where("deletedAt", "==", null),
        ...(limit > 0 ? [fbLimit(limit)] : []),
      );

      if (startAfterDoc) {
        q = query(q, startAfter(startAfterDoc));
      }

      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Failed to list team members:", error);
      throw error;
    }
  }

  /**
   * Checks if user has active membership in an organization
   * @param {string} userId The user to check
   * @param {string} orgId The organization to check against
   * @returns {Promise<boolean>} Whether the user has access
   */
  async function checkOrgAccess(userId, orgId) {
    try {
      const docRef = doc(
        db,
        Collections.ORGANIZATION_MEMBERS,
        `${orgId}_${userId}`,
      );

      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) return false;

      const membership = docSnap.data();
      return membership.deletedAt === null;
    } catch (error) {
      console.error("Failed to check organization access:", error);
      throw error;
    }
  }

  return {
    addUserToOrg,
    addUserToTeam,
    removeUserFromOrg,
    removeUserFromTeam,
    listUserOrgs,
    listOrgMembers,
    listTeamMembers,
    checkOrgAccess,
  };
}
