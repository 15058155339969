import { format as dateFnsFormat, startOfWeek } from "date-fns";

import { DATE_FMT, DATE_FMT_INTERNAL } from "@wc/domain/constants";

/**
 * The start of the work week, aka MONDAY
 * @returns {Date} Last Monday
 */
export function lastMonday() {
  return startOfWeek(new Date(), { weekStartsOn: 1 });
}

/**
 * The Wincardsapp string date format for display
 * @param {Date} date The date to format
 * @returns {string} The formatted date
 */
export function formatDate(date) {
  return dateFnsFormat(date, DATE_FMT);
}

/**
 * The internal string representation of dates
 * @param {Date} date The date to format
 * @returns {string} The formatted date
 */
export function formatDateInternal(date) {
  return dateFnsFormat(date, DATE_FMT_INTERNAL);
}
