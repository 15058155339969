import React from "react";

import { LoginCard } from "@wc/domain/authn";
import usePageTitle from "@wc/ui/hooks/page-title";

/**
 * @returns {React.ReactElement} The Login Page
 */
export default function Login() {
  usePageTitle("Login");
  return <LoginCard />;
}
