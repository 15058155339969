export const PermissionsEnum = Object.freeze({
  ALL: "*",
  // Organization level
  MANAGE_ORGANIZATIONS: "manage:organizations",
  MANAGE_ORGANIZATION: "manage:organization",
  EDIT_ORGANIZATION: "edit:organization",
  VIEW_ORGANIZATION: "view:organization",

  // Organization-wide permissions
  MANAGE_TEAMS: "manage:teams",
  MANAGE_ORGANIZATION_MEMBERS: "manage:organization:members",
  VIEW_TEAMS: "view:teams",
  CREATE_TEAM: "create:team",
  DELETE_TEAM: "delete:team",

  // Organization member operations
  INVITE_ORGANIZATION_MEMBER: "invite:organization:member",
  REMOVE_ORGANIZATION_MEMBER: "remove:organization:member",
  MANAGE_ORGANIZATION_ROLES: "manage:organization:roles",
  VIEW_ORGANIZATION_MEMBERS: "view:organization:members",

  // Single team permissions
  MANAGE_TEAM: "manage:team",
  MANAGE_TEAM_MEMBERS: "manage:team:members",
  EDIT_TEAM: "edit:team",
  VIEW_TEAM: "view:team",

  // Team member operations
  INVITE_TEAM_MEMBER: "invite:team:member",
  REMOVE_TEAM_MEMBER: "remove:team:member",
  MANAGE_TEAM_ROLES: "manage:team:roles",
  VIEW_TEAM_MEMBERS: "view:team:members",
});

export const RolesEnum = Object.freeze({
  SUPER_ADMIN: "SUPER_ADMIN",
  ORG_ADMIN: "ORG_ADMIN",
  ORG_MEMBER: "ORG_MEMBER",
  TEAM_ADMIN: "TEAM_ADMIN",
  TEAM_MEMBER: "TEAM_MEMBER",
});

// This is for saving to the database.
export const RolesNumericEnum = Object.freeze({
  SUPER_ADMIN: 1,
  ORG_ADMIN: 2,
  ORG_MEMBER: 3,
  TEAM_ADMIN: 4,
  TEAM_MEMBER: 5,
});

export const PermissionHierarchy = Object.freeze({
  [PermissionsEnum.ALL]: [
    PermissionsEnum.MANAGE_ORGANIZATIONS,
    PermissionsEnum.MANAGE_ORGANIZATION,
  ],
  [PermissionsEnum.MANAGE_ORGANIZATION]: [
    PermissionsEnum.EDIT_ORGANIZATION,
    PermissionsEnum.VIEW_ORGANIZATION,
    PermissionsEnum.MANAGE_TEAMS,
    PermissionsEnum.MANAGE_ORGANIZATION_MEMBERS,
  ],
  [PermissionsEnum.MANAGE_TEAMS]: [
    PermissionsEnum.CREATE_TEAM,
    PermissionsEnum.VIEW_TEAMS,
    PermissionsEnum.DELETE_TEAM,
    PermissionsEnum.MANAGE_TEAM,
  ],
  [PermissionsEnum.MANAGE_ORGANIZATION_MEMBERS]: [
    PermissionsEnum.INVITE_ORGANIZATION_MEMBER,
    PermissionsEnum.REMOVE_ORGANIZATION_MEMBER,
    PermissionsEnum.MANAGE_ORGANIZATION_ROLES,
    PermissionsEnum.VIEW_ORGANIZATION_MEMBERS,
  ],
  [PermissionsEnum.MANAGE_TEAM]: [
    PermissionsEnum.VIEW_TEAM,
    PermissionsEnum.EDIT_TEAM,
    PermissionsEnum.MANAGE_TEAM_MEMBERS,
  ],
  [PermissionsEnum.MANAGE_TEAM_MEMBERS]: [
    PermissionsEnum.INVITE_TEAM_MEMBER,
    PermissionsEnum.REMOVE_TEAM_MEMBER,
    PermissionsEnum.MANAGE_TEAM_ROLES,
    PermissionsEnum.VIEW_TEAM_MEMBERS,
  ],
});

export const Roles = {
  [RolesEnum.SUPER_ADMIN]: {
    name: "Super Admin",
    permissions: [PermissionsEnum.ALL],
  },

  [RolesEnum.ORG_ADMIN]: {
    name: "Organization Admin",
    permissions: [PermissionsEnum.MANAGE_ORGANIZATION],
  },

  [RolesEnum.ORG_MEMBER]: {
    name: "Organization Member",
    permissions: [
      PermissionsEnum.VIEW_ORGANIZATION,
      PermissionsEnum.VIEW_TEAMS,
      PermissionsEnum.VIEW_ORGANIZATION_MEMBERS,
    ],
  },

  [RolesEnum.TEAM_ADMIN]: {
    name: "Team Admin",
    permissions: [PermissionsEnum.MANAGE_TEAM],
  },

  [RolesEnum.TEAM_MEMBER]: {
    name: "Team Member",
    permissions: [PermissionsEnum.VIEW_TEAM, PermissionsEnum.VIEW_TEAM_MEMBERS],
  },
};
