import FirebaseAdapter from "@wc/adapters/firebase";
import { RolesEnum, roleToNumeric } from "@wc/domain/authz";

/**
 * Add a user to an organization with a specific role
 * @param {object} params The pair of user and org ids
 * @param {string} params.userId The user id
 * @param {string} params.organizationId The org id
 * @param {string} params.role A RolesEnum role
 * @returns {void} Nothing
 */
async function addUserWithRole({ userId, organizationId, role }) {
  await FirebaseAdapter().membership.addUserToOrg(userId, organizationId);

  await FirebaseAdapter().roles.assignOrgRole(
    userId,
    organizationId,
    roleToNumeric(role),
    userId,
  );
}

/**
 * When creating a new user, we'll give them an organization that's just them
 * @param {object} ids The pair of user and org ids
 * @param {string} ids.userId The user id
 * @param {string} ids.organizationId The org id
 * @returns {void} Nothing
 */
export async function addUserToOwnOrg({ userId, organizationId }) {
  addUserWithRole({ userId, organizationId, role: RolesEnum.ORG_ADMIN });
}

/**
 * Load an organization by name. Membership check is enough for now
 * Roles will be checked at later points.
 * @param {string} orgName The name of the organization
 * @param {string} userId the id of the user trying to access
 * @returns {object} The organization
 */
export async function orgIfMemberByName(orgName, userId) {
  const org =
    await FirebaseAdapter().organizations.getOrganizationByName(orgName);

  const isMember = await FirebaseAdapter().membership.checkOrgAccess(
    userId,
    org.id,
  );

  if (isMember) {
    org.createdAt = org.createdAt.toDate();
    return org;
  } else {
    throw new Error(`Unable to access Org ${orgName}`);
  }
}
