import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
} from "@nextui-org/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useAuth } from "@wc/domain/authn";
import { BuildConfig } from "@wc/domain/config";
import { ReactComponent as Building2Icon } from "@wc/ui/assets/icons/building-2.svg";
import { ReactComponent as CupIcon } from "@wc/ui/assets/icons/cup.svg";
import { ReactComponent as FrameIcon } from "@wc/ui/assets/icons/frame.svg";
import { ReactComponent as Logout1Icon } from "@wc/ui/assets/icons/logout-1.svg";
import { ReactComponent as MenuBoardIcon } from "@wc/ui/assets/icons/menu-board.svg";
import { ReactComponent as MoonIcon } from "@wc/ui/assets/icons/moon.svg";
import { ReactComponent as PeopleIcon } from "@wc/ui/assets/icons/people.svg";
import { ReactComponent as WCLogo } from "@wc/ui/assets/logo.svg";

/**
 *
 * @param {object} props The component props
 * @param {string} [props.text] The text to display in the navbar
 * @returns {React.ReactElement} A navbar component
 */
export default function WCNavbar({ text = "" }) {
  const authContext = useAuth();
  const { t } = useTranslation();

  return (
    <Navbar maxWidth="full" shouldHideOnScroll>
      <NavbarBrand>
        <Link to="/">
          <WCLogo role="img" aria-label={text} />
        </Link>
      </NavbarBrand>
      <NavbarContent className="hidden gap-4 sm:flex" justify="center">
        <NavbarItem className="flex gap-2 font-bold">
          <MenuBoardIcon role="img" aria-label="this week" />
          <span>{t("nav.thisWeek")}</span>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent className="gap-4 sm:flex" justify="end">
        {!authContext.isLoggedIn() && (
          <>
            <NavbarItem>
              <Link to="/login">{t("nav.logIn")}</Link>
            </NavbarItem>
            <NavbarItem>
              <Link to="/login">
                <Button color="primary">{t("nav.signUp")}</Button>
              </Link>
            </NavbarItem>
          </>
        )}
        {authContext.isLoggedIn() && (
          <>
            <NavbarItem>
              <Button isIconOnly variant="light">
                <CupIcon role="img" aria-label="achievements" />
              </Button>
            </NavbarItem>
            <NavbarItem>
              <Button
                isIconOnly
                variant="light"
                onClick={() => {
                  const oldPref = localStorage.getItem("darkMode") ?? "light";
                  const newPref = oldPref === "dark" ? "light" : "dark";
                  localStorage.setItem("darkMode", newPref);
                  document.documentElement.classList.toggle(
                    "dark",
                    newPref === "dark",
                  );
                }}
              >
                <MoonIcon role="img" aria-label="dark-mode-preference" />
              </Button>
            </NavbarItem>
            <Dropdown>
              <NavbarItem>
                <DropdownTrigger>
                  <Avatar
                    isBordered
                    radius="lg"
                    name={authContext.getCurrentUser().displayName}
                    className="cursor-pointer"
                  />
                </DropdownTrigger>
              </NavbarItem>
              <DropdownMenu
                aria-label="User Menu"
                className="w-[340px]"
                itemClasses={{
                  base: "gap-4",
                }}
                disabledKeys={["version"]}
              >
                <DropdownItem
                  key="profile"
                  href="/profile"
                  description={t("nav.profileDesc")}
                  startContent={<FrameIcon role="img" aria-label="profile" />}
                >
                  {t("nav.profile")}
                </DropdownItem>
                <DropdownItem
                  key="current-organization"
                  href={`/orgs/${authContext.getCurrentUser().activeOrganization?.name}`}
                  description={
                    authContext.getCurrentUser().activeOrganization?.displayName
                  }
                  startContent={
                    <PeopleIcon role="img" aria-label="organization" />
                  }
                >
                  {t("nav.currentOrg")}
                </DropdownItem>
                <DropdownItem
                  key="all-orgs"
                  href="/orgs"
                  description={t("nav.viewAllOrgsDesc")}
                  startContent={
                    <Building2Icon role="img" aria-label="profile" />
                  }
                >
                  {t("nav.viewAllOrgs")}
                </DropdownItem>
                <DropdownItem
                  key="logout"
                  description={t("nav.logOut")}
                  startContent={<Logout1Icon role="img" aria-label="logout" />}
                  onClick={async () => {
                    await authContext.logout();
                  }}
                />
                <DropdownItem key="version" className="flex justify-center">
                  {BuildConfig.VERSION}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </>
        )}
      </NavbarContent>
    </Navbar>
  );
}
