import {
  collection,
  doc,
  addDoc,
  setDoc,
  updateDoc,
  query,
  where,
  getDocs,
  getDoc,
  limit,
  orderBy,
  startAfter,
  serverTimestamp,
} from "firebase/firestore";

import { Collections } from "./constants";

/**
 * @typedef { import('./types').NoSQLUser } NoSQLUser
 */

/**
 * A top level function to keep track of common inputs to all functions.
 * @param {import('firebase/firestore').Firestore} db The firestore database
 * @returns {object} The functions to access the users collection
 */
export default function usersCollectionFns(db) {
  /**
   * @param {object} options Database query options
   * @param {number} [options.limit] Max number of results to bring back
   * @param {import('firebase/firestore').DocumentSnapshot} [options.startAfterDoc] Document to start after for pagination
   * @returns {Promise<NoSQLUser[]>} The database users
   */
  async function listUsers({ limit = 10, startAfterDoc = null } = {}) {
    try {
      let q = query(
        collection(db, Collections.USERS),
        where("deletedAt", "==", null),
        orderBy("createdAt", "desc"),
        limit(limit),
      );

      if (startAfterDoc) {
        q = query(q, startAfter(startAfterDoc));
      }

      const snapshot = await getDocs(q);

      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error("Failed to list users:", error);
      throw error;
    }
  }

  /**
   * @param {string} userId The id of the user to find
   * @returns {Promise<NoSQLUser|null>} A user or nothing
   */
  async function getUserById(userId) {
    try {
      const docRef = doc(db, Collections.USERS, userId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists() || docSnap.data().deletedAt) {
        throw new Error("No user data available");
      }
      return { id: docSnap.id, ...docSnap.data() };
    } catch (error) {
      console.error("Failed to get user by ID:", error);
      throw error;
    }
  }

  /**
   * @param {string} email the email of the user to find
   * @returns {Promise<NoSQLUser|null>} a user or nothing
   */
  async function getUserByEmail(email) {
    try {
      const q = query(
        collection(db, Collections.USERS),
        where("profile.email", "==", email),
        where("deletedAt", "==", null),
        limit(1),
      );

      const snapshot = await getDocs(q);

      if (snapshot.empty) return null;
      const doc = snapshot.docs[0];
      return { id: doc.id, ...doc.data() };
    } catch (error) {
      console.error("Failed to get user by email:", error);
      throw error;
    }
  }

  /**
   * @param {NoSQLUser} user A user to save
   * @returns {Promise<string>} Returns the user ID
   */
  async function saveUser(user) {
    const { id: userId, ...userData } = user;

    console.log("SAVE USER USER", user);
    console.log("SAVE USER ID", userId);
    console.log("SAVE USER DATA", userData);

    const cleanedUserData = {
      ...userData,
      createdAt: serverTimestamp(),
      lastLogin: serverTimestamp(),
      deletedAt: null,
    };

    try {
      if (userId) {
        await setDoc(doc(db, Collections.USERS, userId), cleanedUserData);
        return userId;
      } else {
        const docRef = await addDoc(
          collection(db, Collections.USERS),
          cleanedUserData,
        );
        return docRef.id;
      }
    } catch (error) {
      console.error("Failed to save user:", error);
      throw error;
    }
  }

  /**
   * @param {string} userId The id of the user to delete
   * @param {string} deletedBy The id of the user who is deleting
   * @returns {Promise<void>} Nothing
   */
  async function deleteUser(userId, deletedBy) {
    try {
      await updateDoc(doc(db, Collections.USERS, userId), {
        deletedAt: serverTimestamp(),
        deletedBy,
      });
    } catch (error) {
      console.error("Failed to delete user:", error);
      throw error;
    }
  }

  return {
    listUsers,
    getUserById,
    getUserByEmail,
    saveUser,
    deleteUser,
  };
}
