import React from "react";

import { OrgContext } from "../contexts/org-context";

/**
 * @returns {object} The organization context data
 */
export default function useOrgByName() {
  const context = React.useContext(OrgContext);

  if (!context) {
    throw new Error("useOrg must be used within an OrgProvider");
  }

  return context;
}
