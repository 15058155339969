/**
 * @typedef { import('./db-models').NoSQLOrganizationRole } NoSQLOrganizationRole
 * @typedef { import('./db-models').NoSQLTeamRole } NoSQLTeamRole
 */

import {
  addDoc,
  collection,
  query,
  where,
  getDocs,
  limit,
  startAfter,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";

import { Collections } from "./constants";

/**
 * A top level function to keep track of common inputs to all functions.
 * @param {import('firebase/firestore').Firestore} db The firestore database
 * @returns {object} The functions to access the roles collections
 */
export default function rolesCollectionsFns(db) {
  /**
   * @param {string} userId The id of the user to assign
   * @param {string} orgId the id of the organization to assign to
   * @param {number} role the role to give the user
   * @param {string} assignedBy the id of the user assigning
   * @returns {Promise<void>} nothing
   */
  async function assignOrgRole(userId, orgId, role, assignedBy) {
    try {
      // First soft-delete any existing active role
      const q = query(
        collection(db, Collections.ORGANIZATION_ROLES),
        where("userId", "==", userId),
        where("organizationId", "==", orgId),
        where("deletedAt", "==", null),
      );

      const existingRoleSnapshot = await getDocs(q);

      if (!existingRoleSnapshot.empty) {
        await updateDoc(existingRoleSnapshot.docs[0].ref, {
          deletedAt: serverTimestamp(),
        });
      }

      // Create new role assignment
      const collectionRef = collection(db, Collections.ORGANIZATION_ROLES);
      await addDoc(collectionRef, {
        userId,
        organizationId: orgId,
        role,
        assignedAt: serverTimestamp(),
        assignedBy,
        deletedAt: null,
      });
    } catch (error) {
      console.error("Failed to assign organization role:", error);
      throw error;
    }
  }

  /**
   * @param {string} userId The id of the user to assign
   * @param {string} teamId The id of the team to assign to
   * @param {number} role The roles to give the user
   * @param {string} assignedBy The id of the user assigning
   * @returns {Promise<void>} nothing
   */
  async function assignTeamRole(userId, teamId, role, assignedBy) {
    try {
      // First soft-delete any existing active role
      const q = query(
        collection(db, Collections.TEAM_ROLES),
        where("userId", "==", userId),
        where("teamId", "==", teamId),
        where("deletedAt", "==", null),
      );

      const existingRoleSnapshot = await getDocs(q);

      if (!existingRoleSnapshot.empty) {
        await updateDoc(existingRoleSnapshot.docs[0].ref, {
          deletedAt: serverTimestamp(),
        });
      }

      // Create new role assignment
      const collectionRef = collection(db, Collections.TEAM_ROLES);
      await addDoc(collectionRef, {
        userId,
        teamId,
        role,
        assignedAt: serverTimestamp(),
        assignedBy,
        deletedAt: null,
      });
    } catch (error) {
      console.error("Failed to assign team role:", error);
      throw error;
    }
  }

  /**
   * Gets the current active role for a user in an organization
   * @param {string} userId The id of the user to query for
   * @param {string} orgId The organization to check
   * @returns {Promise<NoSQLOrganizationRole|null>} A role or nothing
   */
  async function getOrgRole(userId, orgId) {
    try {
      const q = query(
        collection(db, Collections.ORGANIZATION_ROLES),
        where("userId", "==", userId),
        where("organizationId", "==", orgId),
        where("deletedAt", "==", null),
        limit(1),
      );

      const roleSnapshot = await getDocs(q);

      if (roleSnapshot.empty) return null;

      const doc = roleSnapshot.docs[0];
      return {
        id: doc.id,
        ...doc.data(),
      };
    } catch (error) {
      console.error("Failed to get organization role:", error);
      throw error;
    }
  }

  /**
   * Gets the current active role for a user in a team
   * @param {string} userId the id of the user to query for
   * @param {string} teamId the team to check
   * @returns {Promise<NoSQLTeamRole|null>} a role or nothing
   */
  async function getTeamRole(userId, teamId) {
    try {
      const q = query(
        collection(db, Collections.TEAM_ROLES),
        where("userId", "==", userId),
        where("teamId", "==", teamId),
        where("deletedAt", "==", null),
        limit(1),
      );

      const roleSnapshot = await getDocs(q);

      if (roleSnapshot.empty) return null;

      const doc = roleSnapshot.docs[0];
      return {
        id: doc.id,
        ...doc.data(),
      };
    } catch (error) {
      console.error("Failed to get team role:", error);
      throw error;
    }
  }

  /**
   * Gets all organization roles for a user
   * @param {string} userId - The user to get roles for
   * @param {object} options DB query options
   * @param {number} [options.limit] Max number of results to return
   * @param {import('firebase/firestore').DocumentSnapshot} [options.startAfterDoc] Document snapshot to start after for pagination
   * @returns {Promise<NoSQLOrganizationRole[]>} Array of organization roles
   */
  async function getUserOrgRoles(
    userId,
    { limit = 10, startAfterDoc = null } = {},
  ) {
    try {
      let q = query(
        collection(db, Collections.ORGANIZATION_ROLES),
        where("userId", "==", userId),
        where("deletedAt", "==", null),
        limit(limit),
      );

      if (startAfterDoc) {
        q = query(q, startAfter(startAfterDoc));
      }

      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Failed to get user organization roles:", error);
      throw error;
    }
  }

  /**
   * Gets all team roles for a user
   * @param {string} userId The id of the user to query for
   * @param {object} options DB query options
   * @param {number} [options.limit] Max number of results to return
   * @param {import('firebase/firestore').DocumentSnapshot} [options.startAfterDoc] Document snapshot to start after for pagination
   * @returns {Promise<NoSQLTeamRole[]>} A list of teams roles
   */
  async function getUserTeamRoles(
    userId,
    { limit = 10, startAfterDoc = null } = {},
  ) {
    try {
      let q = query(
        collection(db, Collections.TEAM_ROLES),
        where("userId", "==", userId),
        where("deletedAt", "==", null),
        limit(limit),
      );

      if (startAfterDoc) {
        q = query(q, startAfter(startAfterDoc));
      }

      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Failed to get user team roles:", error);
      throw error;
    }
  }

  /**
   * @param {string} orgId The id of the org
   * @param {string} userId the id of the user
   * @returns {Promise<void>} nothing
   */
  async function removeOrgRole(orgId, userId) {
    try {
      const q = query(
        collection(db, Collections.ORGANIZATION_ROLES),
        where("userId", "==", userId),
        where("organizationId", "==", orgId),
        where("deletedAt", "==", null),
        limit(1),
      );

      const roleSnapshot = await getDocs(q);

      if (!roleSnapshot.empty) {
        await updateDoc(roleSnapshot.docs[0].ref, {
          deletedAt: serverTimestamp(),
        });
      }
    } catch (error) {
      console.error("Failed to remove organization role:", error);
      throw error;
    }
  }

  /**
   * @param {string} teamId The id of the team
   * @param {string} userId the id of the user
   * @returns {Promise<void>} nothing
   */
  async function removeTeamRole(teamId, userId) {
    try {
      const q = query(
        collection(db, Collections.TEAM_ROLES),
        where("userId", "==", userId),
        where("teamId", "==", teamId),
        where("deletedAt", "==", null),
        limit(1),
      );

      const roleSnapshot = await getDocs(q);

      if (!roleSnapshot.empty) {
        await updateDoc(roleSnapshot.docs[0].ref, {
          deletedAt: serverTimestamp(),
        });
      }
    } catch (error) {
      console.error("Failed to remove team role:", error);
      throw error;
    }
  }

  return {
    assignOrgRole,
    assignTeamRole,
    getOrgRole,
    getTeamRole,
    getUserOrgRoles,
    getUserTeamRoles,
    removeOrgRole,
    removeTeamRole,
  };
}
