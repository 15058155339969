import React from "react";

import usePageTitle from "@wc/ui/hooks/page-title";

/**
 * @returns {React.ReactElement} The org list page
 */
export default function Orgs() {
  usePageTitle("List Orgs");
  return <div>Orgs list page</div>;
}
