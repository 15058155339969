import FirebaseAdapter from "@wc/adapters/firebase";
import {
  userHasPermissionInOrg,
  PermissionsEnum,
  numericToRole,
} from "@wc/domain/authz";

/** @typedef { import("@wc/domain/users").AuthnUser } AuthnUser */
/** @typedef { import("../models").OrgListUser } OrgListUser */

/**
 * @param {string[]} userIds The list of users to find
 * @returns {object} An object of userIds mapped to their respective role object
 */
async function getOrgUsers(userIds) {
  try {
    const promises = userIds.map((userId) =>
      FirebaseAdapter().users.getUserById(userId),
    );
    const allUsers = await Promise.all(promises);

    return allUsers.reduce((acc, u) => {
      if (u && u.id) {
        acc[u.id] = u;
      }
      return acc;
    }, {});
  } catch (error) {
    console.log("Unable to get all users", error);
    throw error;
  }
}

/**
 * @param {string} organizationId The org to check
 * @param {string[]} userIds The list of users to find
 * @returns {object} An object of userIds mapped to their respective role object
 */
async function getOrgMembersRoles(organizationId, userIds) {
  try {
    const promises = userIds.map((userId) =>
      FirebaseAdapter().roles.getOrgRole(userId, organizationId),
    );
    const allRoles = await Promise.all(promises);

    return allRoles.reduce((acc, role) => {
      if (role && role.userId) {
        acc[role.userId] = role;
      }
      return acc;
    }, {});
  } catch (error) {
    console.log("Unable to get all roles", error);
    throw error;
  }
}

/**
 * @param {string} organizationId The id of the organization to check
 * @param {string} accessUserId The user requesting access
 * @returns {OrgListUser[]} The users in the organization
 */
export async function listOrgMembers(organizationId, accessUserId) {
  const canAccess = await FirebaseAdapter().membership.checkOrgAccess(
    accessUserId,
    organizationId,
  );
  if (!canAccess) {
    throw new Error(`Unable to access organization ${organizationId}`);
  }

  const members = await FirebaseAdapter().membership.listOrgMembers(
    organizationId,
    { limit: 0 },
  );

  const users = await getOrgUsers(members.map((m) => m.userId));

  const roles = await getOrgMembersRoles(
    organizationId,
    members.map((m) => m.userId),
  );

  const finalMembers = [];

  members.forEach((m) => {
    const user = users[m.userId];

    if (!user) {
      return;
    }

    const finalM = {
      id: user.id,
      displayName: user.profile.displayName,
      createdAt: user.createdAt.toDate(),
    };

    const roleData = roles[m.userId];
    if (!roleData) {
      return;
    }

    finalM.role = numericToRole(roleData.role);
    finalMembers.push(finalM);
  });

  return finalMembers;
}

/**
 * @param {string} organizationId The organization to look at
 * @param {string} userId The user to remove
 * @param {AuthnUser} accessUser A full authenticated user to check
 */
export async function removeOrgMember(organizationId, userId, accessUser) {
  if (userId === accessUser.id) {
    throw new Error("You cannot delete yourself");
  }

  const hasRemovePermission = await userHasPermissionInOrg(
    accessUser,
    organizationId,
    PermissionsEnum.REMOVE_ORGANIZATION_MEMBER,
  );

  if (!hasRemovePermission) {
    throw new Error("You do not have permission to remove this user");
  }
  await FirebaseAdapter().roles.removeOrgRole(organizationId, userId);
  await FirebaseAdapter().membership.removeUserFromOrg(userId, organizationId);
}
