import { Spinner } from "@nextui-org/react";
import React from "react";
import { Outlet, Navigate } from "react-router-dom";

import useAuth from "../hooks/use-auth";

/**
 * For use with routes that need authentication.
 * @returns {React.ReactElement} The authenticating layout.
 */
export function AuthnLayout() {
  const authData = useAuth();

  if (authData.isLoading()) {
    return (
      <div className="mt-8 flex justify-center">
        <Spinner color="primary" />
      </div>
    );
  }

  if (!authData.isLoggedIn()) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
}
