import FirebaseAdapter from "@wc/adapters/firebase";

/**
 * @param {object} userInfo Some basic information about the user
 * @param {string} userInfo.displayName The name of the user
 * @param {string} userInfo.email The email of the user
 * @param {string | null} [userInfo.id] The id of the user. If null, this function will create one.
 * @param {string} orgId The organization id for this user, it will be the active one
 * @returns {string} The new database id
 */
export default async function createUser(
  { displayName, email, id = null },
  orgId,
) {
  console.log(id, orgId);
  const dbId = await FirebaseAdapter().users.saveUser({
    id: id,
    profile: {
      displayName: displayName,
      email: email,
      photoURL: null,
    },
    settings: {
      theme: "light",
    },
    metadata: {
      activeOrganizationId: orgId,
      accountStatus: "active",
    },
    createdAt: new Date(),
    lastLogin: new Date(),
  });

  return dbId;
}
