import { Tab, Tabs, Spinner } from "@nextui-org/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, Outlet, useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "@wc/domain/authn";

import OrgProvider from "../contexts/org-context";
import useOrg from "../hooks/use-org";

/**
 * @returns {React.ReactElement} Inside the provider so we can get org information
 */
function OrgSettingsLayoutInternal() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const orgData = useOrg();

  const validTabKeys = ["overview", "info", "members"];
  const currentPath = location.pathname.split("/").pop();
  const defaultSelectedKey = validTabKeys.includes(currentPath)
    ? currentPath
    : "overview";

  if (!orgData.org) {
    return (
      <div className="mt-8 flex justify-center">
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <div>
      <h1 className="mb-4 text-4xl font-bold tracking-tight">
        {t("org.settingsTitle")}{" "}
        <span className="text-primary">{orgData?.org.displayName}</span>
      </h1>
      <Tabs
        variant="bordered"
        color="primary"
        selectedKey={defaultSelectedKey}
        onSelectionChange={(key) =>
          navigate(`/orgs/${orgData?.org.name}/${key}`)
        }
      >
        {validTabKeys.map((tk) => {
          return <Tab key={tk} title={t(`org.tab-${tk}`)} />;
        })}
      </Tabs>
      <div className="mt-4">
        <Outlet />
      </div>
    </div>
  );
}

/**
 * This is a layout that should always be used in the context of an organization.
 * This expects there to be an orgName in the url parameters.
 * @returns {React.ReactElement} The layout for the org settings pages
 */
export function OrgSettingsLayout() {
  const { orgName } = useParams();
  const authData = useAuth();

  return (
    <OrgProvider orgName={orgName} userId={authData.getCurrentUser().id}>
      <OrgSettingsLayoutInternal />
    </OrgProvider>
  );
}
