import FirebaseAdapter from "@wc/adapters/firebase";

import { PermissionHierarchy, Roles } from "../constants";

import { numericToRole } from "./role-translate";

/** @typedef { import("@wc/domain/users").AuthnUser } AuthnUser */

/**
 * @param { string[] } permissions A list of permissions
 * @returns { string[] } A flattened list of permissions extracted from the hierarchy
 */
function expandPermissions(permissions) {
  const expanded = new Set();

  /**
   * @param {string} permission A single permission to expand
   * @returns {void} Adds to the expanded set
   */
  function expand(permission) {
    expanded.add(permission);
    if (PermissionHierarchy[permission]) {
      PermissionHierarchy[permission].forEach((subPermission) => {
        expanded.add(subPermission);
        expand(subPermission);
      });
    }
  }

  permissions.forEach(expand);
  return Array.from(expanded);
}

/**
 * Checks if user has a specific permission based on their roles
 * @param {string[]} userRoles Array of role names assigned to user
 * @param {string} permission Permission to check for
 * @returns {boolean} True if user has permission, false otherwise
 */
export function hasPermission(userRoles, permission) {
  const allPermissions = userRoles.flatMap((role) =>
    expandPermissions(Roles[role]?.permissions || []),
  );
  return allPermissions.includes(permission);
}

/**
 * @param {AuthnUser} user A full authenticated user to check
 * @param {string} organizationId The organization to look at
 * @param {string} permission Permission to check for
 * @returns {boolean} True if user has permission, false otherwise
 */
export async function userHasPermissionInOrg(user, organizationId, permission) {
  const roleData = await FirebaseAdapter().roles.getOrgRole(
    user.id,
    organizationId,
  );

  const userRole = numericToRole(roleData.role);

  return hasPermission([userRole], permission);
}
