import React from "react";

import { OrgMemberList } from "@wc/domain/org-membership";

/**
 * @returns {React.ReactElement} The members page
 */
export default function OrgMembers() {
  return <OrgMemberList readOnly={false} />;
}
