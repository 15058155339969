import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";

/**
 * @param {import('firebase/auth').Auth} fbAuth The firebase auth object
 * @returns {object} Auth functions
 */
export default function authnFns(fbAuth) {
  /**
   * Initiates Google sign-in popup flow.
   * @returns {Promise<import('firebase/auth').User>} Promise resolving to the signed-in user
   * @throws {Error} If sign-in fails
   */
  async function signInWithGoogle() {
    const googleProvider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(fbAuth, googleProvider);
      return result.user;
    } catch (error) {
      console.error(error.code);
      throw error;
    }
  }

  /**
   * Signs out the current user.
   * @returns {Promise<void>}
   */
  async function signout() {
    await signOut(fbAuth);
  }

  /**
   * Gets the currently signed-in user.
   * @returns {import('firebase/auth').User | null} The current user or null if not signed in
   */
  function getCurrentUser() {
    return fbAuth.currentUser;
  }

  /**
   * Sets up an observer for authentication state changes.
   * @param {(user: import('firebase/auth').User | null) => void} callback Function called when auth state changes
   * @returns {() => void} Unsubscribe function
   */
  function onAuthStateChanged(callback) {
    return fbAuth.onAuthStateChanged(callback);
  }

  return {
    signInWithGoogle,
    signout,
    getCurrentUser,
    onAuthStateChanged,
  };
}
