import { CardHeader, CardBody, CardFooter, Button } from "@nextui-org/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as GoogleLogo } from "@wc/ui/assets/google-logo.svg";
import SingleCardOnPage from "@wc/ui/components/single-card-on-page";

import useAuth from "../../hooks/use-auth";

/**
 * An icon button for auth methods
 * @param {object} props The component props
 * @param {React.ReactElement} props.children The body of the button
 * @returns {React.ReactElement} The OAuthButton
 */
function OAuthButton({ children }) {
  const navigate = useNavigate();
  const auth = useAuth();

  return (
    <Button
      isIconOnly
      size="lg"
      radius="full"
      onPress={async () => {
        const result = await auth.loginOAuth();
        if (result) {
          navigate("/");
        }
      }}
    >
      {children}
    </Button>
  );
}

/**
 * @returns {React.ReactElement} A login card
 */
export default function LoginCard() {
  const { t } = useTranslation();

  return (
    <SingleCardOnPage>
      <CardHeader className="flex justify-center">
        <h1 className="font-bold sm:text-2xl">{t("loginHeader")}</h1>
      </CardHeader>
      <CardBody className="flex flex-row justify-center gap-4">
        <OAuthButton>
          <GoogleLogo role="img" aria-label={"Google"} />
        </OAuthButton>
      </CardBody>
      <CardFooter className="flex justify-center">
        {t("loginFooter")}
      </CardFooter>
    </SingleCardOnPage>
  );
}
