import { Card, CardBody } from "@nextui-org/react";
import React from "react";

import { useAuth } from "@wc/domain/authn";
import { PermissionsEnum, userHasPermissionInOrg } from "@wc/domain/authz";
import { formatDate } from "@wc/domain/dates";
import EditableInput from "@wc/ui/components/editable-input";

import useOrg from "../hooks/use-org";

/**
 * A page dedicated to displaying organization information
 * @param {object} props The component props
 * @param {boolean} props.readOnly Whether to allow editing or not, regardless of permissions
 * @returns {React.ReactElement} The component
 */
export default function OrgInfo({ readOnly = true }) {
  const orgData = useOrg();
  const userData = useAuth();
  const [hasManagePermission, setHasManagePermission] = React.useState(false);

  React.useEffect(() => {
    const fetchPermissions = async () => {
      if (readOnly) {
        setHasManagePermission(false);
        return;
      }

      try {
        const hasPerm = await userHasPermissionInOrg(
          userData.getCurrentUser(),
          orgData?.org.id,
          PermissionsEnum.MANAGE_ORGANIZATION,
        );
        setHasManagePermission(hasPerm);
      } catch (err) {
        console.error(err);
        setHasManagePermission(false);
      }
    };

    if (orgData?.org.id && userData.getCurrentUser()) {
      fetchPermissions();
    }
  }, [orgData?.org.id, userData, readOnly]);

  return (
    <Card className="p-3">
      <CardBody>
        <ul className="flex flex-col gap-4">
          <li className="flex items-center gap-4">
            <b>Name: </b>
            <EditableInput
              isDisabled={!hasManagePermission}
              value={orgData.org.displayName}
              onSave={async (newName) =>
                await orgData.updateOrgDisplayName(newName)
              }
            />
          </li>
          <li className="flex gap-4">
            <b>Created: </b>
            {formatDate(orgData.org.createdAt)}
          </li>
        </ul>
      </CardBody>
    </Card>
  );
}
