import { createOrganization, addUserToOwnOrg } from "@wc/domain/organizations";
import { createUser } from "@wc/domain/users";

/**
 * The flow for signing up in the system.
 * This will create a user, a default organization and add them
 * as an admin for that organization
 * @param {object} userInfo Some basic information about the user
 * @param {string} userInfo.displayName The name of the user
 * @param {string} userInfo.email The email of the user
 * @param {string | null} [userInfo.id] The id of the user. If null, this function will create one.
 */
export default async function signup({ displayName, email, id = null }) {
  const createdOrganizationId = await createOrganization({ name: displayName });

  const createdUserId = await createUser(
    { displayName, email, id },
    createdOrganizationId,
  );

  await addUserToOwnOrg({
    userId: createdUserId,
    organizationId: createdOrganizationId,
  });
}
