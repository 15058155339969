import {
  collection,
  query,
  where,
  getDocs,
  startAfter,
} from "firebase/firestore";

import { Collections } from "./constants";

/**
 * @typedef { import('./types').NoSQLTeam } NoSQLTeam
 */

/**
 * @param {import('firebase/firestore').Firestore} db The firestore database
 * @returns {object} The teams collection access functions
 */
export default function teamsCollectionFns(db) {
  /**
   * @param {object} options DB query options
   * @param {number} [options.limit] Max number of results to return
   * @param {string | null} [options.startAfterId] id to start after, since firestore doesn't have offset
   * @returns {Promise<NoSQLTeam[]>} A list of teams
   */
  async function listTeams({ limit = 10, startAfterId = null } = {}) {
    try {
      let q = query(
        collection(db, Collections.TEAMS),
        where("deletedAt", "==", null),
        limit(limit),
      );

      if (startAfterId) {
        q = query(q, startAfter(startAfterId));
      }

      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error("Failed to list teams:", error);
      throw error;
    }
  }

  /**
   * @param {string} orgId The id of the organization to list for
   * @param {object} options DB query options
   * @param {number} [options.limit] Max number of results to return
   * @param {string | null} [options._startAfterId] id to start after, since firestore doesn't have offset
   * @returns {Promise<NoSQLTeam[]>} A list of teams
   */
  async function listTeamsByOrganization(
    orgId,
    { limit = 10, _startAfterId = null } = {},
  ) {
    try {
      const snapshot = await db
        .collection(Collections.TEAMS)
        .where("organizationId", "==", orgId)
        .where("deletedAt", "==", null)
        .limit(limit)
        .offset(offset)
        .get();

      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error("Failed to list teams by organization:", error);
      throw error;
    }
  }

  /**
   * @param {string} teamId The id of the team to retrieve
   * @returns {Promise<NoSQLTeam|null>} A team or nothing
   */
  async function getTeam(teamId) {
    try {
      const doc = await db.collection(Collections.TEAMS).doc(teamId).get();
      if (!doc.exists || doc.data().deletedAt) return null;
      return { id: doc.id, ...doc.data() };
    } catch (error) {
      console.error("Failed to get team:", error);
      throw error;
    }
  }

  /**
   * @param {NoSQLTeam} team The team to save
   * @returns {Promise<string>} Returns the team ID
   */
  async function saveTeam(team) {
    try {
      if (team.id) {
        await db.collection(Collections.TEAMS).doc(team.id).set(team);
        return team.id;
      } else {
        const docRef = await db.collection(Collections.TEAMS).add({
          ...team,
          createdAt: new Date(),
          deletedAt: null,
        });
        return docRef.id;
      }
    } catch (error) {
      console.error("Failed to save team:", error);
      throw error;
    }
  }

  /**
   * @param {string} teamId The id of the team to delete
   * @param {string} deletedBy The id of the user who is deleting
   * @returns {Promise<void>} Nothing
   */
  async function deleteTeam(teamId, deletedBy) {
    try {
      await db.collection(Collections.TEAMS).doc(teamId).update({
        deletedAt: new Date(),
        deletedBy,
      });
    } catch (error) {
      console.error("Failed to delete team:", error);
      throw error;
    }
  }

  return {
    listTeams,
    listTeamsByOrganization,
    getTeam,
    saveTeam,
    deleteTeam,
  };
}
