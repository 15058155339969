import React from "react";
import { useParams, Navigate } from "react-router-dom";

/**
 * @returns {React.ReactElement} The org list page
 */
export default function OrgName() {
  const { orgName } = useParams();

  return <Navigate to={`/orgs/${orgName}/overview`} />;
}
