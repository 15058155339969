import { Spinner, Card, CardHeader, CardBody } from "@nextui-org/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { OrgMemberList } from "@wc/domain/org-membership";
import { useOrg, OrgInfo } from "@wc/domain/organizations";
import usePageTitle from "@wc/ui/hooks/page-title";

/**
 *
 * @param {object} props The component props
 * @param {string} props.url The url to link to
 * @returns {React.ReactElement} The See more link component
 */
function SeeMoreLink({ url }) {
  const { t } = useTranslation();
  return (
    <Link to={url} className="text-primary">
      {t("seeMore")}
    </Link>
  );
}

/**
 * @returns {React.ReactElement} The internal page with access to org data
 */
export default function OrgOverview() {
  const orgData = useOrg();
  const { t } = useTranslation();

  usePageTitle(orgData.org?.displayName || "");

  if (!orgData.org) {
    return (
      <div className="mt-8 flex justify-center">
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <div className="m-5 flex flex-col gap-4">
      <Card className="m-5">
        <CardHeader className="flex items-center justify-between">
          <h2 className="text-xl font-bold">{t("org.tab-info")}</h2>
          <SeeMoreLink url={`/orgs/${orgData.org?.name}/info`} />
        </CardHeader>
        <CardBody>
          <OrgInfo />
        </CardBody>
      </Card>
      <Card className="m-5">
        <CardHeader className="flex items-center justify-between">
          <h2 className="text-xl font-bold">{t("org.tab-members")}</h2>
          <SeeMoreLink url={`/orgs/${orgData.org?.name}/members`} />
        </CardHeader>
        <CardBody>
          <OrgMemberList />
        </CardBody>
      </Card>
    </div>
  );
}
