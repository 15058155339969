import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import authnFns from "./authn.js";
import membershipCollectionsFns from "./membership";
import organizationsCollectionFns from "./organizations";
import rolesCollectionsFns from "./roles";
import teamsCollectionFns from "./teams";
import usersCollectionFns from "./users";

let FBInstance;

/**
 * @param {string} apiKey The firebase api key
 * @param {string} authDomain the firebase authorization domain
 * @param {string} projectId the firebase project id
 * @param {string} appId the firebase app id
 * @returns {object} The Firebase Adapter
 */
export function initializeFirebaseAdapter(
  apiKey,
  authDomain,
  projectId,
  appId,
) {
  if (FBInstance) {
    return FBInstance;
  }

  const config = {
    apiKey,
    authDomain,
    projectId,
    appId,
  };

  const _app = initializeApp(config);
  const firebaseAuth = getAuth();
  const firestoreDB = getFirestore();

  const FBAdapter = {
    authn: authnFns(firebaseAuth),
    users: usersCollectionFns(firestoreDB),
    teams: teamsCollectionFns(firestoreDB),
    organizations: organizationsCollectionFns(firestoreDB),
    membership: membershipCollectionsFns(firestoreDB),
    roles: rolesCollectionsFns(firestoreDB),
  };

  FBInstance = FBAdapter;
  return FBAdapter;
}

/**
 * @returns {object} the firebase adapter
 */
export default function FirebaseAdapter() {
  if (FBInstance) {
    return FBInstance;
  } else {
    throw new Error("FirebaseAdapter is uninitialized");
  }
}
