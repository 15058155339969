import { RolesEnum, RolesNumericEnum } from "../constants";

/**
 * Converts a numeric role value to its string role equivalent
 * @param {number} numericRole - The numeric role value from RolesNumericEnum
 * @returns {string} The corresponding string role from RolesEnum
 * @throws {Error} If invalid numeric role is provided
 */
export function numericToRole(numericRole) {
  const entry = Object.entries(RolesNumericEnum).find(
    ([_, value]) => value === numericRole,
  );
  if (!entry) {
    throw new Error(`Invalid numeric role: ${numericRole}`);
  }
  return RolesEnum[entry[0]];
}

/**
 * Converts a string role to its numeric role equivalent
 * @param {string} role - The string role value from RolesEnum
 * @returns {number} The corresponding numeric role from RolesNumericEnum
 * @throws {Error} If invalid role string is provided
 */
export function roleToNumeric(role) {
  const numericRole = RolesNumericEnum[role];
  if (!numericRole) {
    throw new Error(`Invalid role: ${role}`);
  }
  return numericRole;
}
