import { orgIfMemberByName } from "./org-membership";

/**
 * Load an organization by name. Membership check is enough for now
 * Roles will be checked at later points.
 * @param {string} orgName The name of the organization
 * @param {string} userId The id of the user trying to access
 * @returns {object} The organization
 */
export async function loadOrganization(orgName, userId) {
  const org = await orgIfMemberByName(orgName, userId);
  return org;
}
