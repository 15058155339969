import { Checkbox, Input, Kbd } from "@nextui-org/react";
import React from "react";
import { isMacOs, isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";

/**
 *
 * @param {object} props The component props
 * @param {React.RefObject<React.ReactElement>} props.inputRef - Ref for the input element
 * @param {number} props.index The index of the input if in a list.
 * @param {boolean} props.isLast Whether this is the last element in the list
 * @param {boolean} props.isFinal Whether this is the final list item
 * @param {string} props.placeholderText A default input string
 * @param {string} props.valueText Actual data for the input
 * @param {boolean} props.checked Whether the input is checked or not
 * @param {(index: number) => void} props.onEnter Callback when the Enter key is pressed
 * @param {(checked: boolean, value: string, index: number) => void} props.onChange Callback when the value changes key
 * @param {(index: number, isShiftKey: boolean) => void} props.onTab Callback when the Tab key is pressed
 * @returns {React.ReactElement} An input with a checkbox
 */
export default function CheckboxInput({
  inputRef,
  index,
  isLast,
  isFinal,
  placeholderText = "",
  valueText = "",
  checked = false,
  onEnter = (_index) => {},
  onChange = (_checked, _value, _index) => {},
  onTab = (_index, _isShiftKey) => {},
}) {
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = React.useState(checked);
  const [value, setValue] = React.useState(valueText);

  const enterKeyName = isMacOs || isIOS ? t("keyReturn") : t("keyEnter");

  React.useEffect(() => {
    setIsSelected(checked);
    setValue(valueText);
  }, [checked, valueText]);

  const checkboxRef = React.useRef(null);

  React.useEffect(() => {
    if (checkboxRef.current) {
      const checkboxElement = checkboxRef.current.querySelector(
        'input[type="checkbox"]',
      );
      if (checkboxElement) {
        checkboxElement.tabIndex = -1;
      }
    }
  }, []);

  return (
    <div className="flex w-full gap-2">
      <div ref={checkboxRef} className="flex items-center">
        <Checkbox
          isSelected={isSelected}
          onValueChange={(newCheck) => {
            setIsSelected(newCheck);
            onChange(newCheck, value, index);
          }}
          size="md"
        />
      </div>
      <div className="relative grow">
        <Input
          ref={inputRef}
          type="text"
          size="lg"
          radius="sm"
          variant="bordered"
          placeholder={placeholderText}
          value={value}
          onValueChange={(newValue) => {
            setValue(newValue);
            onChange(isSelected, newValue, index);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              if (value.length > 0) {
                onEnter(index);
              }
            } else if (e.key === "Tab") {
              onTab(index, e.shiftKey);
            }
          }}
        />
        {valueText && isLast && !isFinal && (
          <div className="absolute -bottom-2 -right-2">
            <Kbd keys={["enter"]}>
              {t("hitEnter", { keyName: enterKeyName })}
            </Kbd>
          </div>
        )}
      </div>
    </div>
  );
}
