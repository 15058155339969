import React from "react";

import WinCardEditable from "@wc/domain/components/win-card";
import usePageTitle from "@wc/ui/hooks/page-title";

/**
 * @returns {React.ReactElement} The home page
 */
export default function Home() {
  usePageTitle("Home");
  return (
    <div>
      <WinCardEditable />
    </div>
  );
}
